import axios from "axios";

class Api {
  constructor(url = "http://localhost:3001") {
    const ax = axios.create({
      baseURL: url,
      headers: {
        'X-Custom-Header': ';-) Looking for a job? Send careers@zeitgeist.pm an email.'
      }
    });

    this.ax = ax;
  }

  async fetchTotal() {
    const { data } = await this.ax.get('/totalSales');
    return data;
  }

  async getPrice() {
    const { data } = await this.ax.get('/price');
    return data;
  }
  
  async getStartTime() {
    const { data } = await this.ax.get('/startTime');
    return data;
  }

  async latestSerial() {
    const { data } = await this.ax.get('/latestSerial');
    return data;
  }

  async getBroadcast() {
    const { data } = await this.ax.get('/broadcast');
    return data;
  }

  async incBroadcast() {
    await this.ax.post('/incBroadcast');
  }

  async canPurchase(address) {
    const { data } = await this.ax.get(`/canPurchase/${address}`);
    console.log(data);
    return data;
  }

  async startSale(address) {
    const res = await this.ax.post('/startSale', {
      address,
    });

    console.log('startSale', res);

    return res.data;
  }

  async completeSale(address, blockHash) {
    const res = await this.ax.post('/completeSale', {
      address,
      blockHash,
    });

    console.log('completeSale', res);
  }

  async cancelSale(address) {
    const res = await this.ax.post('/cancelSale', {
      address,
    });

    console.log('cancelSale', res);
  }
}


export default Api;
