import {
  web3Accounts,
  web3Enable,
  web3FromAddress,
} from '@polkadot/extension-dapp';
import { useCallback, useEffect, useState } from "react";
import DotLoader from "react-spinners/DotLoader";
import { ApiPromise, WsProvider } from "@polkadot/api";

import Api from "./api";
import Timer from "./components/Timer";
import Subscribe from "./components/Subscribe";

const FUNDS_RECEIVER = 'ES1CC93wMHeUQZF4heC55Mhn4WGXfX5Hw72jqVQrvpEqfQC';

const images = [
  'Tarot-000-Fool-NF_1.gif',
  'Tarot-001-Magician-NF_1.gif',
  'Tarot-002-Priestess-NF_1.gif',
  'Tarot-003-Empress-NF_1.gif',
  'Tarot-004-Emperor-NF_1.gif',
  'Tarot-005-Hierophant-NF_1.gif',
  'Tarot-006-Lovers-NF_1.gif',
  'Tarot-007-Chariot-NF_1.gif',
  'Tarot-008-Strength-NF_1.gif',
  'Tarot-009-Hermit-NF_1.gif',
  'Tarot-010-Wheel-NF_1.gif',
  'Tarot-011-Justice-NF_1.gif',
  'Tarot-013-Death-NF_1.gif',
  'Tarot-014-Temperance-NF_1.gif',
  'Tarot-015-Devil-NF_1.gif',
  'Tarot-016-Tower-NF_1.gif',
  'Tarot-017-Star-NF_1.gif',
];

const COLLECTION_NAME = "JUSTICE";
// const MAX_MINTS = 1000;

function App() {

  /** Globals */
  const [connected, setConnected] = useState(false);
  const [active, setActive] = useState(null);
  const [allAccounts, setAllAccounts] = useState(null);
  const [total, setTotal] = useState(null);
  const [canPurchase, setCanPurchase] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [txComplete, setTxComplete] = useState(false);
  const [price, setPrice] = useState(false);
  const [startTime, setStartTime] = useState(0);

  /** Modals */
  const [walletModal, setWalletModal] = useState(false);
  const [buyModal, setBuyModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(false);

  /** Context specifics */
  const [newWallet, setNewWallet] = useState(null);
  const [emailSuccess, setEmailSuccess] = useState(false);

  /** Api instances */
  const [ax, setAx] = useState(null);
  const [pdApi, setPdApi] = useState(null);

  const pastTargetTime = Date.now() > startTime;

  const updateData = useCallback(async () => {
    if (!ax) return;
    console.log('updating data');
    const t = await ax.latestSerial();
    const p = await ax.getPrice();
    const ti = await ax.getStartTime();
    if (active && active.address) {
      const c = await ax.canPurchase(active.address);
      setCanPurchase(c);
    }
    setTotal(t.serial);
    setPrice(p);
    setStartTime(ti);
  }, [ax, active]);

  useEffect(() => {
    const myInterval = setInterval(async () => {
      updateData();
    }, 2000);

    return () => {
      clearInterval(myInterval);
    }
  });

  useEffect(() => {
    if (!ax) {
      const a = new Api("https://tarot-server.zeitgeist.pm");
      // const a = new Api("http://localhost:3001");
      setAx(a);
    }

    const initPdApi = async () => {
      const isProd = true;
      const endpoint = isProd ? "wss://kusama-rpc.polkadot.io" : "ws://localhost:9944";
      const p = await ApiPromise.create({
        provider: new WsProvider(endpoint),
      })
      setPdApi(p);
    }

    if (!pdApi) {
      initPdApi();
    }

    updateData();
  }, [ax, active, pdApi, updateData]);

  const openModal = (type) => {
    closeModals();

    if (type === 'wallet') {
      setWalletModal(true);
    }
    if (type === 'buy') {
      setBuyModal(true);
    }
    if (type === 'rules') {
      setRulesModal(true);
    }
  }

  const closeModals = () => {
    setBuyModal(false);
    setRulesModal(false);
    setWalletModal(false);
  }

  const sendTx = async () => {
    setModalLoading(true);
    const injector = await web3FromAddress(active.address);
    const started = await ax.startSale(active.address);

    if (!started) {
      // display message
      alert("Your account has already purchased or the collection has sold out!");
      setModalLoading(false);
      closeModals();
      return;
    };

    pdApi.tx.balances.transfer(FUNDS_RECEIVER, price).signAndSend(active.address, { signer: injector.signer }, async (result) => {
    // pdApi.tx.system.remark("test").signAndSend(active.address, { signer: injector.signer }, async (result) => {
      const { status, events } = result;
      console.log(status.toHuman())
      if (status.isBroadcast) {
        await ax.incBroadcast();
      }
      if (status.isFinalized) {
        console.log('finalized', status.asFinalized.toString())
        const failed = events.find(({ event }) => {
          return pdApi.events.system.ExtrinsicFailed.is(event);
        });

        if (!!failed) {
          // error
          console.log('error');
          // cancel sale
          await ax.cancelSale(active.address);
          setModalLoading(false);
          alert("An error occurred with your transaction")
        } else {
          console.log('success');
          //conclude sale
          await ax.completeSale(active.address, status.asFinalized.toString());
          setModalLoading(false)
          setTxComplete(true);
        }
      }
    }).catch(async (err) => {
      console.error(err);
      await ax.cancelSale(active.address);
      setModalLoading(false);
      alert("User cancelled transaction");
    });
  }

  const onClick = async () => {
    await web3Enable('Zeitgeist Tarot Cards');
    const allAccounts = await web3Accounts({ ss58Format: 2 });
    setAllAccounts(allAccounts);
    setActive(allAccounts[0])
    setConnected(true);
  }

  const changeWallet = async () => {
    const account = allAccounts.find((acc) => {
      return acc.address === newWallet;
    });
    setActive(account);
    closeModals();
  }

  return (
    <div className="early-bird flex h-screen w-screen justify-center" style={{ backgroundColor: "#0001FE" }}>
      <div className="flex h-screen w-screen">
        <div className="flex flex-col w-full h-screen">

          <div className="flex flex-row items-center text-white px-6 py-6">
            <div className="flex flex-row items-center w-1/2">
              <img src="/Logo.png" className="h-9 mr-3" alt="Zeitgeist Logo"/>
              <h1 className="font-semibold text-2xl mr-3">Zeitgeist</h1>
              <img src="/rectangle.png" className="mr-3" alt="rectangle" />
              <h1 className="font-semibold text-2xl mr-3">Beta</h1>
              <img src="/rectangle.png" className="mr-3" alt="rectangle" />
              <h1 className="font-semibold text-2xl">NFT Tickets</h1>
            </div>

            <div className="flex flex-row w-1/2">
              <div className="w-full" />
              <div className="flex flex-col w-96">
                {/* {
                  connected 
                    ? (
                    <div 
                      className="bg-black text-white font-semibold py-2 flex flex-row items-center justify-between px-4 hover:cursor-pointer"
                      onClick={() => openModal('wallet')}
                    >
                      <Identicon
                        value={active.address}
                        size={32}
                        theme={'polkadot'}
                      />
                      <div className="font-mono w-full flex justify-center">{active.address.slice(0, 6)}...{active.address.slice(-6)}</div>
                    </div>
                    ) : (
                      <button className="bg-black text-white font-semibold py-3" onClick={onClick}>Connect Wallet</button>
                    )
                } */}
              </div>
            </div>
          </div>
          
          <div className="flex flex-row px-6 mt-8">
            <div className="flex flex-col w-full text-white">
              <h1 className="font-bold text-6xl mb-4">Early Unlimited Access to Zeitgeist Beta</h1>
              <p>Get early access to Zeitgeist's Beta application for prediction markets with these exclusive NFTs.{' '}
                {/* <span>
                  <a href="/#" className="hover:text-black underline" style={{ color: "#F7FF58" }}>More info</a>
                </span> */}
              </p>
            </div>
          </div>

          <div className="px-12 pt-0 my-auto">
            <h1 className="text-white text-3xl mb-6">{COLLECTION_NAME} Collection:</h1>
            <div className="pt-0 grid grid-cols-5 gap-4">
              {[11,12,13,14,15].map(num => (
                <img key={num} src={`/cards/${images[num]}`} className="h-auto" alt="card" />
              ))}
            </div>
          </div>

          <div className="flex flex-row justify-between items-center" style={{ borderTop: "1px solid #C4C4C4"}}>
          
            <div className="flex flex-row items-center justify-between h-full w-1/2 p-4" style={{ borderRight: "1px solid #C4C4C4" }}>
              <p className="text-white font-mono text-sm pr-4">Stay notified on the latest news from Zeitgeist</p>
              {
                emailSuccess
                  ? (
                    <div
                      className="h-full flex items-center justify-center bg-black text-white font-semibold"
                      style={{ width: "32rem" }}
                      onClick={() => setEmailSuccess(false)}
                    >Thank you!</div>
                  ) : <Subscribe onSuccess={setEmailSuccess} />
                }
            </div>

            <div className="flex flex-row items-center justify-between w-1/2 h-full p-4">
              <div className="flex flex-col text-white">
                <h1 className="font-semibold text-xl">Zeitgeist Tarot Cards</h1>
                <p className="font-mono text-lg">Listing starts at 13:00 UTC</p>
              </div>

              <div className="flex flex-row h-full">
                <div className="bg-black flex flex-row items-center justify-around p-2 px-4 w-36">
                  <img src="/kusama.svg" className="" alt="Kusama Logo" />
                  <div className="h-full bg-white mx-2" style={{ width: "1px" }} />
                  <p className="font-medium text-xl text-white">{(0.05).toFixed(2)}</p>
                </div>
                <button
                  className="font-semibold w-48" 
                  style={{ background: "#FF0054" }}
                  onClick={() => {
                    window.open('https://singular.rmrk.app/collections/102cb9e9988c85201e-ZEITGEIST-JUSTICE-TAROT-COLLECTION?page=1&forsale=forSale&sortBy=priceAscending')
                  }}
                >Buy On Singular</button>
                {
                  (() => {
                    if (canPurchase && pastTargetTime) {
                      return (
                        <>
                          <div className="bg-black flex flex-row items-center justify-around p-2 px-4 w-36">
                            <img src="/kusama.svg" className="" alt="Kusama Logo" />
                            <div className="h-full bg-white mx-2" style={{ width: "1px" }} />
                            <p className="font-medium text-xl text-white">0.05</p>
                          </div>
                          <button
                            className="font-semibold w-32" 
                            style={{ background: "#FF0054" }}
                            onClick={() => {
                              if (!connected) {
                                alert("Please connect your wallet first.");
                                return;
                              }
                              openModal('buy');
                              onClick();
                              console.log(total);
                            }}
                          >Buy</button>
                        </>
                      );
                    } else if (!canPurchase && pastTargetTime) {
                      return (
                        <div className="bg-black text-white flex justify-center items-center" style={{ width: "17rem" }}>
                          Complete
                        </div>
                      );
                    } else if (!pastTargetTime) {
                      return (
                        <div className="bg-black text-white flex justify-center items-center" style={{ width: "17rem" }}>
                          <Timer targetTime={startTime} />
                        </div>
                      )
                    } else {
                      return (
                        <div className="bg-black text-white flex justify-center items-center" style={{ width: "17rem" }}>
                          Complete
                        </div>
                      );
                    }
                  })
                }
                </div>

            </div>

          </div>

        </div>

        {
          buyModal &&
          <div className="absolute top-0 w-screen h-screen flex justify-center items-center" style={{ background: "rgba(0,0,0,0.5)"}}>
            <div className="py-5 px-10 w-96" style={{ background: "#0001FE" }}>
              {modalLoading
                ? <div className="flex flex-col justify-center items-center h-44 text-white">
                    <h1>Your transactions is processing! This will take a couple minutes, <span className="font-black">do not refresh or click out of this page.</span></h1>
                    <DotLoader size={150} color="#FFFFFF" />
                  </div>
                : txComplete
                  ? <div className="flex flex-col text-white">
                      <h2>Your transaction is complete!</h2>
                      <h2 className="mb-4">Please allow up to 20 minutes for your NFT to appear on Singular.</h2>

                      <div className="flex flex-row justify-between">
                        <button
                          className="py-2 px-5 font-semibold font-mono text-sm"
                          style={{ background: "#FF0054"}}
                          onClick={() => window.open(`https://singular.rmrk.app/space/${active.address.toString()}`)}
                        >Go to Singular</button>
                        <button
                          className="py-2 px-10 text-white font-semibold font-mono" 
                          style={{ background: "none", border: "1px solid white"}}
                          onClick={() => {
                            closeModals();
                            setTxComplete(false);
                          }}
                        >Close</button>
                      </div>
                      
                    </div>
                  : (
                      <>
                      <h1 className="font-bold text-4xl text-white mb-2">The Fool Collection</h1>
                      <h1 className="font-bold text-4xl text-white mb-2">NFT Card</h1>
                      <h2 className="text-white font-bold text-xl mb-6">Drop 1-3</h2>

                      <div className="flex flex-row mb-6">
                        <div className="flex items-center justify-center px-6 font-semibold" style={{ background: "#F7FF58"}}>Price</div>
                        <div className="bg-black flex flex-row p-2 px-4">
                          <img src="/kusama.svg" className="h-6" alt="Kusama Logo" />
                          <div className="h-full bg-white mx-2" style={{ width: "1px" }} />
                          <p className="font-medium text-xl text-white">{(price / 10**12).toFixed(2)}</p>
                        </div>
                      </div>

                      <div className="flex flex-row justify-between">
                        <button
                          className="py-2 px-10 font-semibold font-mono"
                          style={{ background: "#FF0054"}}
                          onClick={sendTx}
                        >Confirm</button>
                        <button
                          className="py-2 px-10 text-white font-semibold font-mono" 
                          style={{ background: "none", border: "1px solid white"}}
                          onClick={closeModals}
                        >Cancel</button>
                      </div>
                      </>
                    )
              }

            </div>
          </div>
        }
        {
          walletModal &&
          <div className="absolute top-0 w-screen h-screen flex justify-center items-center" style={{ background: "rgba(0,0,0,0.5)"}}>
            <div className="py-5 px-10 w-96" style={{ background: "#0001FE" }}>
              <h1 className="font-bold text-4xl text-white mb-6">Change Wallet</h1>

              <select
                className="px-3 py-2 font-mono mb-6"
                style={{ background: "#A3BDDB" }}
                onChange={(e) => {
                  const { value } = e.target;
                  console.log(value)
                  setNewWallet(value);
              }}
              >
                {allAccounts.map(account => (
                  <option
                    value={account.address}
                    key={account.address}
                  >{account.address.slice(0, 6)}...{account.address.slice(-6)}</option>
                ))}
              </select>

              <div className="flex flex-row justify-between">
                <button
                  className="py-2 px-10 font-semibold font-mono"
                  style={{ background: "#FF0054"}}
                  onClick={changeWallet}
                >Confirm</button>
                <button
                  className="py-2 px-10 text-white font-semibold font-mono" 
                  style={{ background: "none", border: "1px solid white"}}
                  onClick={closeModals}
                >Cancel</button>

              </div>
            </div>
          </div>
        }
        {
          rulesModal &&
          <div className="absolute top-0 w-screen h-screen flex justify-center items-center" style={{ background: "rgba(0,0,0,0.5)"}}>
            <div className="py-5 px-10 w-96" style={{ background: "#0001FE" }}>
              <h1 className="font-bold text-4xl text-white mb-6">Change Wallet</h1>

              <select>
                {allAccounts.map(account => (
                  <option key="account">{account.address}</option>
                ))}
              </select>

              <div className="flex flex-row justify-between">
                <button className="py-2 px-10 font-semibold font-mono" style={{ background: "#FF0054"}}>Confirm</button>
                <button
                  className="py-2 px-10 text-white font-semibold font-mono" 
                  style={{ background: "none", border: "1px solid white"}}
                  onClick={closeModals}
                >Cancel</button>

              </div>
            </div>
          </div>
        }

      </div>
    </div>
  );
}

export default App;
