import React, { useState, useEffect } from "react";

const Timer = (props) => {
  const {
    targetTime = 1633971600000
  } = props;

  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);


  useEffect(() => {
    let myInterval = setInterval(() => {
      const now = Date.now();
      const diff = targetTime - now; 

      const h   = Math.floor(diff / 3.6e6);
      const m = Math.floor((diff % 3.6e6) / 6e4);
      const s = Math.floor((diff % 6e4) / 1000);

      setHours(h);
      setMinutes(m);
      setSeconds(s);
    }, 1000);

    return () => {
      clearInterval(myInterval);
    }
  });

  return (
    <div>
      {hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
    </div>
  )
}

export default Timer;
