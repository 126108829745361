import React from "react";
import { useForm } from "react-hook-form";
import * as EmailValidator from "email-validator";

// const GhostAdminAPI = require('@tryghost/admin-api');

// const ghostApi = !process.env.NEXT_PUBLIC_GHOST_KEY ? {} :
//   new GhostAdminAPI({
//       url: 'https://blog.zeitgeist.pm',
//       version: "v3",
//       key: process.env.NEXT_PUBLIC_GHOST_KEY,
//   });

const Subscribe = ({ onSuccess }) => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { email } = data;
    console.log(email);

    if (!EmailValidator.validate(email)) {
      return;
    }

    // await ghostApi.members.add({ email });
    onSuccess(true);
  }

  return (
    <form className="flex flex-row h-full" style={{ width: "32rem" }} onSubmit={handleSubmit(onSubmit)}>
      <input
        className="w-3/5 h-full px-3 font-mono text-sm" 
        style={{ background: "#A3BDDB" }} 
        placeholder="Enter Your Email"
        {...register('email', { required: true })}
      />
      <input
        type="submit"
        className="h-full w-2/5 font-semibold"
        style={{ background: "#F7FF58" }}
      />
    </form>
  )
}

export default Subscribe;
